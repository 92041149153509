import React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import Seo from "~/components/seo"
import Logo from "~/components/nav/common_logo_wh.svg"
import Arrow from "~/images/common_icon_arrow_r.svg"
import MenuModal from "~/components/pages/order/menuModal"
import Subscription from "../components/pages/order/subscription"
import * as containerStyles from "~/components/pages/order/order.module.scss"

const ApplyPage = () => (
  <>
    <Helmet>
      <script defer>
        {`
    {
      function loadingInit() {
        let loadingElem = document.getElementById('loadingArea');
        loadingElem.classList.remove("is-visible");
      }
      function ShopifyBuyInitA() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275034833104',
            node: document.getElementById('collection-component-1629351318787'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("A：275034833104 renderd");
                    loadingInit();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }

      function ShopifyBuyInitB() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275188121808',
            node: document.getElementById('collection-component-1634282878021'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("B：275188121808 renderd");
                    ShopifyBuyInitA();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }

      function ShopifyBuyInitC() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275188154576',
            node: document.getElementById('collection-component-1634623032999'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("C：275188154576 renderd");
                    ShopifyBuyInitB();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }
      function ShopifyBuyInitD() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275190153424',
            node: document.getElementById('collection-component-1634630769444'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("D：275188154576 renderd");
                    ShopifyBuyInitC();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }

      function ShopifyBuyInitE() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275188187344',
            node: document.getElementById('collection-component-1634631026679'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("E：275188154576 renderd");
                    ShopifyBuyInitD();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }

      function ShopifyBuyInitF() {
        var client = ShopifyBuy.buildClient({
          domain: 'shpree-snish.myshopify.com',
          storefrontAccessToken: 'bd6fcd58441343b7e495adb948fb7ebf',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('collection', {
            id: '275712409808',
            node: document.getElementById('collection-component-1635754303823'),
            moneyFormat: '%C2%A5%7B%7Bamount_no_decimals%7D%7D',
            options: {
              "product": {
                "iframe": false,
                "styles": {
                },
                "contents": {
                  "img": false,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "カートに入れる"
                }
              },
              "productSet": {
                "iframe": false,
                events: {
                  afterRender: function (component) {
                    console.log("F：275712409808 renderd");
                    ShopifyBuyInitE();
                  }
                },
              },
              "option": {},
              "cart": {
                iframe: false,
                "text": {
                  "total": "合計",
                  "button": "レジへ進む",
                  notice: '送料はこの後のステップで自動計算されます。2足以上でメニューが異なる場合は、注文メモ欄でご説明をお願いします。',
                  noteDescription: '注文メモ',
                },
                "contents": {
                  "note": true
                },
                "popup": false
              },
              lineItem: {
                contents: {
                  image: false,
                }
              },
              "toggle": {
                iframe: false
              }
            },
          });

        });
      }

      if(window.location.href.includes("order")) {
        ShopifyBuyInitF();
      }

    }
  `}
      </script>
    </Helmet>

    <Seo title="Order" pageUrl="order" />
    <div id="loadingArea" className="orderLoading is-visible">
      <div className="loadingContent">
        <div>
          <div role="img" aria-label="Loading">
            <Logo />
          </div>
          <div className="loadingBar"></div>
        </div>
      </div>
    </div>
    <div className="pageComponent">
      <nav className="contentComponent">
        <ul className="breadcrumbComponent mincho">
          <li>
            <Link to="/" itemProp="url">
              TOP
            </Link>{" "}
            <span>
              <Arrow />
            </span>
          </li>
          <li>お申し込みメニューの選択</li>
        </ul>
      </nav>

      <header className={containerStyles.orderHeader + " titleComponent"}>
        <h1 className="contentComponent heading with-english">
          <span>お申し込みメニューの選択</span>Order
        </h1>
        <hr className="borderLine" />
      </header>

      <div
        className={containerStyles.orderMenuDescription + " contentComponent"}
      >
        <MenuModal />
      </div>

      <div className="contentComponent acordionComponent for-order">
        <section className="acordionWrapper for-pumps">
          <header className="acordionSwitch">
            <h2 className="heading is-secondary">
              パンプス・ハイヒールのヒール修理
            </h2>
            <p>パンプス・ハイヒールのヒール直し/交換をするメニュー</p>
          </header>
          <div
            id="collection-component-1629351318787"
            className="acordionContent"
          ></div>
        </section>

        <section className="acordionWrapper for-loafers">
          <header className="acordionSwitch">
            <h2 className="heading is-secondary">
              ローファー・革靴のかかと修理
            </h2>
            <p>ローファーや革靴のかかと直し/交換をするメニュー</p>
          </header>
          <div
            id="collection-component-1634282878021"
            className="acordionContent"
          ></div>
        </section>

        <section className="acordionWrapper for-loafersAll">
          <header className="acordionSwitch">
            <h2 className="heading is-secondary">
              ローファー・革靴の靴底(全体・ハーフ)の修理/交換
            </h2>
            <p>
              ▼オールソール
              <br />
              靴底全体を新しくするメニュー
              <br />
              ラバーソール（ゴム靴）と革・レザーソール（革・レザー製）の2種類があります。
              <br />
              ▼ハーフラバー
              <br />
              靴底の前半分を補強するメニュー
              <br />
              お色は、ブラック・ダークブラウン・ベージュ・レッドの4種類があります。
              <br />
              <span className="smallTxt">
                *それぞれの修理箇所に合う素材やお色で対応いたします。お色のご希望がありましたら注文シールの備考欄にご記入ください。
              </span>
            </p>
          </header>
          <div className="acordionContent">
            <div>
              <section>
                <h3 className="heading is-secondary">
                  オールソール（ラバー）交換
                </h3>
                <div id="collection-component-1634623032999"></div>
              </section>
              <section>
                <h3 className="heading is-secondary">
                  オールソール（革・レザー）交換
                </h3>
                <div id="collection-component-1634630769444"></div>
              </section>
              <section>
                <h3 className="heading is-secondary">ハーフラバー交換</h3>
                <div id="collection-component-1635754303823"></div>
              </section>
            </div>
          </div>
        </section>

        <section className="acordionWrapper for-cleaning">
          <header className="acordionSwitch">
            <h2 className="heading is-secondary">
              靴磨き/抗菌などクリーニングのみ
            </h2>
            <p>
              修理は不要で、シューシャインやウィルス殺菌・抗菌・脱臭のみ依頼するメニュー
              <br />
              ヒールでも革靴でもご利用いただけます。
            </p>
          </header>
          <div
            id="collection-component-1634631026679"
            className="acordionContent"
          ></div>
        </section>

        <Subscription
          linkUrl="https://shpree-snish.myshopify.com/apps/subscription/buy?id=42175076958416&selling_plan=585007312"
          title="保管コース"
          text="すぐに靴の保管を行いたいお客様へ。今すぐに目の前のコンビニでも可能。最短翌日にはご自宅・旅行先にも、集荷にお伺い出来るスピード保管コース。あなたの大切な靴を１足から最適な環境下でお預かりします。"
          price="１足あたり　660円/月"
        />

        <Subscription
          linkUrl="https://shpree-snish.myshopify.com/apps/subscription/buy?id=43202050097360&selling_plan=1070694608"
          title="保管コース+靴磨き"
          text="お客様それぞれの靴の状態に合わせ、職人が一足一足丁寧に、生まれ変わる靴を楽しみながら磨き上げます。環境にも人にも優しい最高級靴クリームを使用し、靴の素材や色に合わせたメンテナンスを行った後に保管いたします。"
          price="１足あたり　660円/月　（初月のみ＋3,300円）"
        />

        <Subscription
          linkUrl="https://shpree-snish.myshopify.com/apps/subscription/buy?id=43202050523344&selling_plan=1070727376"
          title="保管コース+ウイルス殺菌・抗菌・脱臭"
          text="自宅の靴箱だとカビが生える事が多いのですが、、、そんなお悩みにお答えできるのがこちらのプランです。カビの元を靴専用ウイルス殺菌機械で処理を施してから保管します。また臭いの原因も脱臭しますので、返却した際に気持ちよく履いて頂けます。"
          price="１足あたり　660円/月　（初月のみ＋3,300円）"
        />
        
        <Subscription
          linkUrl="https://shpree-snish.myshopify.com/apps/subscription/buy?id=43202051571920&selling_plan=1070760144"
          title="保管コース+靴磨き+ウイルス殺菌・抗菌・脱臭"
          text="冠婚葬祭用で自宅に置いておいたらカビが生えてしまった！そんな人におすすめなのがこちらのプランです。一度、職人が綺麗にしてからカビ菌などを殺菌して保管。いざという時に、メンテナンスされた靴が必要な時に数日で戻ってきますので、ハレの日の靴を保管させていただく事が多いプランです。"
          price="１足あたり　660円/月　（初月のみ＋4,400円）"
        />

      </div>
    </div>
  </>
)

export default ApplyPage
