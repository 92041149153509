import React, { useState } from 'react'
import Modal from "react-modal";
import Icon from "~/images/common_icon_help.svg";
import CloseIcon from "~/images/common_icon_close.svg";
import * as containerStyles from '~/components/pages/order/order.module.scss'

Modal.setAppElement("#___gatsby");

const MenuModal = () => {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  function modalOpen(e) {
    e.preventDefault();
    console.log('You clicked submit.');
  }

  const modalStyle = {
    overlay: {
      zIndex: "99999",
      backgroundColor: "rgba(16, 16, 16, 0.3)"
    },
    content: {
      padding: 0,
      zIndex: "99999",
      border: 0,
      borderRadius: 0,
      backgroundColor: "#F5F2EA"
    }
  };

  return (
    <>
      <button type="button" onClick={() => setIsOpen(true)} className={containerStyles.orderModalSwitch}>
        <span><Icon />メニュー説明</span>
      </button>

      <Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <div className="modalWrap">
          <section className={containerStyles.orderMenuContent}>
          <button onClick={() => setIsOpen(false)} className={containerStyles.orderModalClose}><CloseIcon /></button>
            <h2 className="heading is-primary">メニュー説明</h2>
            <dl className={containerStyles.orderMenuDl}>
              <div>
                <dt className="heading is-secondary">パンプス・ハイヒールのヒール修正</dt>
                <dd>ヒールの擦り減りや、ヒール全体の修理・交換。ヒールに合うお色を選んで修理させていただきます。</dd>
              </div>
              <div>
                <dt className="heading is-secondary">ローファー・革靴のかかと修正</dt>
                <dd>ローファー・革靴のかかとの擦り減りや、かかと全体の修理・交換。かかとに合うお色を選んで修理させていただきます。</dd>
              </div>
              <div>
                <dt className="heading is-secondary">ローファー・革靴の靴底全体の修理/交換（オールソール）</dt>
                <dd>靴底全体の修理・交換。ラバーソール（ゴム製）と、革・レザーソール（革・レザー製）の2種類があります。</dd>
              </div>
              <div>
                <dt className="heading is-secondary">ハーフラバー</dt>
                <dd>革靴の底面前半分に、厚さ数mmのゴムを貼る修理。グリップ力の向上や、底面の耐久性を高める効果があります。ブラック、ダークブラウン、ベージュ、レッドの中から合うお色を選んで修理させていただきます。</dd>
              </div>
              <div>
                <dt className="heading is-secondary">靴磨き（シューシャイン）</dt>
                <dd>お客様それぞれの靴の状態に合わせ、職人が一足一足丁寧に磨き上げます。環境にも人にも優しい最高級靴クリームを使用し、靴の素材や色に合わせたメンテナンスを行っています。</dd>
              </div>
              <div>
                <dt className="heading is-secondary">ウイルス殺菌・抗菌・脱臭</dt>
                <dd>靴専用の殺菌・脱臭機で、靴を傷めることなく行っています。</dd>
              </div>
            </dl>
          </section>
        </div>
      </Modal>
    </>
  )
}

export default MenuModal
